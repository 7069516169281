import { Col, Container, Row } from 'react-bootstrap';
import Layout from '../Layout/Layout';

const Privacy = () => {
    return (
        <Layout>
            <>
                <Container className='layout-article'>
                    <Row>
                        <Col>
                            <h1>AVISO DE PRIVACIDAD</h1>
                            <p className='text-end'>
                                01 de enero del 2025
                            </p>  
<p>
INVERSIONES POP, S.A.P.I. de C.V. y/o en cualquiera de sus subsidiaras y/o filiales (en lo sucesivo “POP
INVESTMENTS”) con domicilio en: Diego de Montemayor 551. Col. Centro, Monterrey, N.L. CP 64000, así
como todas sus empresas afiliadas, es responsable del tratamento de sus datos personales. Su información
personal será utilizada para darle cumplimiento a las obligaciones dispuestas por la Ley Federal de
Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita, así como darle trámite a los
procesos y servicios institucionales que usted ha solicitado, relacionados con las operaciones productivas
y/o comerciales de POP INVESTMENTS, dentro de las cuales se incluyen, de forma enunciativa más no
limitativa, desarrollo y administración de bienes inmuebles, su comercialización, la selección y contratación
de personal y/o servicios, licitaciones, informarle sobre cambios en los mismos y evaluar la calidad de
servicio que le brindamos, así como cualquier otro que coadyuven al desempeño de su objeto social y para
llevar a cabo la relación con candidatos en nuestra bolsa de trabajo, empleados, clientes y proveedores, en
su caso.</p>
<p>
Para las finalidades antes previstas, podemos recolectar sus datos personales de distintas formas: cuando
usted nos los proporciona directamente; cuando visita nuestro sitio de Internet, correo electrónico, o
cuando utiliza nuestros servicios en línea, así como otras fuentes que están permitidas por la Ley Federal de
Protección de Datos Personales en posesión de los Particulares.</p>
<p>
Los datos personales que tratamos para las finalidades antes mencionadas, son: de identificación, laborales
y datos académicos, como lo es el nombre completo, fecha y lugar de nacimiento, nacionalidad, dirección,
teléfono, correo electrónico, estado civil, nombre de la empresa para la que trabaja, domicilio del trabajo,
CURP, RFC y Cédula de Identificación Fiscal, identificación oficial vigente, comprobante de domicilio,
referencias familiares y/o personales, firma.</p>
<p>
Usted tiene derecho de acceder, rectificar y cancelar sus datos personales, así como de oponerse al
tratamento de los mismos o revocar el consentimiento que para tal fin nos haya autorizado, a través de los
procedimientos que hemos implementado. Para conocer dichos procedimientos, los requisitos y plazos, y
ejercer sus derechos de acceso, rectificación, cancelación u oposición (ARCO) de sus datos de carácter
personal, es necesario que presente su petición en <b>juridico@popinvestments.com</b>. Su solicitud debe
contener: nombre y domicilio del titular, documentos que acrediten la identidad de la misma o carta poder
en caso que sea por representación legal del titular del dato, teléfono u otro medio para comunicarle la
respuesta a su solicitud y descripción clara y precisa de los datos personales sobre los cuales se busca ejercer
los derechos ARCO.</p>
<p>
    Toda solicitud de ejercicio de los derechos descritos deberá cumplir los requisitos contenidos en la Ley
Federal de Protección de Datos Personales en posesión de los Particulares y será resuelta en los plazos y
bajo los términos que esta Ley señale.</p>
<p>En el caso que POP INVESTMENTS recibiera datos personales sensibles de los usuarios se obtendrá el
consentimiento expreso y por escrito del titular para su tratamento, a través de su firma autógrafa, firma
electrónica, o cualquier mecanismo de autentificación.</p>
<p>Usted, al dar su consentimiento a este aviso de privacidad, acepta que sus datos personales sean
compartidos con nuestras empresas afiliadas, partes relacionadas y empresas de servicios que se relacionen
con el curso ordinario del negocio de POP INVESTMENTS para las finalidades señaladas en este aviso de
privacidad.</p>
<p>
Cualquier modificación o actualización al Aviso de Privacidad se
encontrará disponible en la página de internet <a href="www.popinvestments.com" target="_blank">www.popinvestments.com</a>
</p>
<p>
Atentamente,<br />
<b>POP INVESTMENTS S.A. DE C.V</b>
</p>
                        </Col>
                    </Row>
                </Container>
            </>
        </Layout>
    )
}

export default Privacy;
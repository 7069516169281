import { Table } from "react-bootstrap";
import './AreaFinanceTable.scss'

const AreaFinanceTable = () => {

    return (
        <div className="table-finance--wp">
            <Table className="table-finance" bordered hover>
                <thead className='bg-primary text-white text-center'>
                    <tr>
                        <th>Descripción</th>
                        <th>Enganche inicial %</th>
                        <th>Construcción %</th>
                        <th>Escriturar %</th>
                    </tr>
                </thead>
                <tbody className="text-center">
                    <tr>
                        <td>10 - 20 - 70%</td>
                        <td>10%</td>
                        <td>20%</td>
                        <td>70%</td>
                    </tr>
                    <tr>
                        <td>15 - 15 - 70%</td>
                        <td>15%</td>
                        <td>15%</td>
                        <td>70%</td>
                    </tr>
                    <tr>
                        <td>20 - 10 - 70%</td>
                        <td>20%</td>
                        <td>10%</td>
                        <td>70%</td>
                    </tr>
                    <tr>
                        <td>30 - 0 - 70%</td>
                        <td>30%</td>
                        <td>0%</td>
                        <td>70%</td>
                    </tr>
                    <tr>
                        <td>90 - 0 - 10%</td>
                        <td>90%</td>
                        <td>0%</td>
                        <td>10%</td>
                    </tr>
                </tbody>
            </Table>
            <p className="table-finance--disclaimer ps-4">
                * Separa con $15,000 MXN
            </p>
        </div>
    )
}

export default AreaFinanceTable;
import { Col, Container, Row } from 'react-bootstrap';
import Layout from '../Layout/Layout';

const NonDiscrimination = () => {
    return (
        <Layout>
            <>
                <Container className='layout-article'>
                    <Row>
                        <Col>
                            <p>
                            01 de enero del 2025
                            </p>
                            <h1 className='mb-4'>AVISO DE NO DISCRIMINACIÓN</h1>
                            <p>
                            En Pop Investments y todas sus filiales, no se discrimina por motivos de raza, religión, orientación sexual,
condición física o socio económica ni por ningún otro motivo, entendiéndose por discriminación la negación,
exclusión, distinción, menoscabo, impedimento o restricción de alguno o algunos de los derechos humanos
de las personas, grupos y comunidades en situación de discriminación imputables a personas físicas o morales
o entes públicos con intención o sin ella, dolosa o culpable, por acción u omisión, por razón de su origen
étnico, nacional, lengua, sexo, género, identidad indígena, expresión de rol de género, edad, discapacidad,
condición jurídica, social o económica, apariencia física, condiciones de salud, características genéticas,
embarazo, religión, opiniones políticas, académicaso filosóficas, identidad o filiación política, orientación o
preferencia sexual, estado civil, por su forma de pensar, vestir, actuar, gesticular, por tener tatuajes o
perforaciones corporales o cualquier otra que tenga por efecto anular o menoscabar el reconocimiento, goce
o ejercicio, de los derechos y libertades fundamentales, así como la igualdad de las personas.
                            </p>
                            <p>
                                Atentamente,<br />
                                <b>POP INVESTMENTS S.A. DE C.V.</b>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </>
        </Layout>
    );
}

export default NonDiscrimination;
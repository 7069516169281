import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import './Footer.scss';

const Footer = () => {
    return (
        <footer className="footer">
            <span className="footer--background"></span>
            <Container fluid>
                <Row className="align-items-center justify-content-center text-center">
                    <Col xs='2' lg='2'>
                        <Button href="https://renderinc.mx/" target="_blank" variant="text"><span className="lg-renderinc"></span></Button>
                    </Col>
                    <Col xs='2' lg='2' className="py-1">
                        <span className="copyright">&copy; PORTIKO <span className="year">{new Date().getFullYear()}</span></span>
                    </Col>
                    <Col xs='12' lg='4' className="text-center">
                        <p>
                            <span className="icon icon--location"></span> Juan I. Ramón #1202 Col. Centro, Mty N.L. C.P. 64000
                        </p>
                        <Row className="footer--legals align-items-center">
                            <Col >
                                <sup>*</sup>Precios sujetos a disponibilidad.
                            </Col>
                        </Row>
                    </Col>
                    <Col xs='3' lg='1'>
                        <Link to="/aviso-de-privacidad"><Button variant='text'>Aviso de Privacidad</Button></Link>
                    </Col>
                    <Col xs='3' lg='1'>
                        <Link to="/aviso-de-no-discriminacion"><Button variant='text'>Aviso de no discriminación</Button></Link>
                    </Col>
                    <Col xs='3' lg='2'>
                        <span className="lg-investments"></span>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer;
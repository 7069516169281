//console.warn("process.env.NODE_ENV:",process.env.NODE_ENV);
const BASE_URL = process.env.NODE_ENV === 'production' ? "./" : "./";
const PROJECTS_DIR = BASE_URL+"projects/";
const PROJECT_DIR = PROJECTS_DIR+"portiko/";
export const LOCAL_API_BASE = 'https://demo.devhouse.mx/atiko-api/public/api/v1';
const project = {
    id: 1,
    name: "Portiko",
    description: "Demo project",
    status: true,
    styles: {
        colors: {
            primary: "#FF2A000",
        },
        intro : {
            backgroundColor: "rgba(43, 28, 69, 1)",
            logo: PROJECT_DIR + "intro-logo.svg",
            profile: PROJECT_DIR + "profile-logo.svg",
        }
    },
    assets : {
        masterplan : {
            transitionIn : PROJECT_DIR + "transitions/D-A.mp4", 
        },
        spinner : {
            sides : [
                {   id: 1,
                    endFrame: PROJECT_DIR + 'spinner/A.jpg',
                    transitions : {
                        left: PROJECT_DIR + 'spinner/A-trans-left.mp4',
                        right: PROJECT_DIR + 'spinner/A-trans-right.mp4',
                        overhead: PROJECT_DIR + 'spinner/A-trans-overhead.mp4'
                    }
                },
                {   id: 2,
                    endFrame: PROJECT_DIR + 'spinner/B.jpg',
                    transitions : {
                        left: PROJECT_DIR + 'spinner/B-trans-left.mp4',
                        right: PROJECT_DIR + 'spinner/B-trans-right.mp4',
                        overhead: PROJECT_DIR + 'spinner/B-trans-overhead.mp4'
                    }
                },
                {   id: 3,
                    endFrame: PROJECT_DIR + 'spinner/C.jpg',
                    transitions : {
                        left: PROJECT_DIR + 'spinner/C-trans-left.mp4',
                        right: PROJECT_DIR + 'spinner/C-trans-right.mp4',
                        overhead: PROJECT_DIR + 'spinner/C-trans-overhead.mp4'
                    }
                },
                {   id: 4,
                    endFrame: PROJECT_DIR + 'spinner/D.jpg',
                    transitions : {
                        left: PROJECT_DIR + 'spinner/D-trans-left.mp4',
                        right: PROJECT_DIR + 'spinner/D-trans-right.mp4',
                        overhead: PROJECT_DIR + 'spinner/D-trans-overhead.mp4'
                    }
                },
            ]
        },
        galleries: [
          {
            code: 'FL1', 
            prototypes: ['1FL-1'],
            images: [
              {
                url: PROJECT_DIR + 'floors-galleries/floor-1/img-cowork-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-1/img-cowork-portiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-1/img-happy_room-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-1/img-happy_room-portiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-1/img-juegos_infantiles-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-1/img-juegos_infantiles-portiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-1/img-lab_creativo-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-1/img-lab_creativo-portiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-1/img-lobby-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-1/img-lobby-portiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-1/img-petpark-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-1/img-petpark-portiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-1/img-terraza-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-1/img-terraza-portiko.jpg',
                width: 320,
                height: 174,
              },
            ],
          },
          {
            code: 'FL10', 
            prototypes: ['2FL-10'],
            images: [
              {
                url: PROJECT_DIR + 'floors-galleries/floor-10/img-adadores_2-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-10/img-adadores_2-portiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-10/img-alberca_2-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-10/img-alberca_2-portiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-10/img-alberca-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-10/img-alberca-portiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-10/img-arcade_room-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-10/img-arcade_room-portiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-10/img-asadores-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-10/img-asadores-portiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-10/img-cancha-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-10/img-cancha-portiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-10/img-chefs_room-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-10/img-chefs_room-portiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-10/img-escaladora-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-10/img-escaladora-portiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-10/img-family_room-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-10/img-family_room-portiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-10/img-gym_libre-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-10/img-gym_libre-portiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-10/img-gym-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-10/img-gym-portiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-10/img-karaoke-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-10/img-karaoke-portiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-10/img-ludoteca-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-10/img-ludoteca-portiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-10/img-salon_de_eventos-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-10/img-salon_de_eventos-portiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-10/img-suite_de_visitas-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-10/img-suite_de_visitas-portiko.jpg',
                width: 320,
                height: 174,
              },
            ],
          },
          {
            code: 'FL33', 
            prototypes: ['2FL-33'],
            images: [
              {
                url: PROJECT_DIR + 'floors-galleries/floor-10/img-alberca-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-10/img-alberca-portiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-10/img-cancha-multi-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-10/img-cancha-multi-portiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-10/img-chefs_room-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-10/img-chefs_room-portiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-10/img-muro-escalar-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-10/img-muro-escalar-portiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-10/img-salon-eventos_2-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-10/img-salon-eventos_2-portiko.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'floors-galleries/floor-10/img-salon-eventos-portiko.jpg',
                original: PROJECT_DIR + 'floors-galleries/floor-10/img-salon-eventos-portiko.jpg',
                width: 320,
                height: 174,
              },
            ],
          },
          {
            code: 'G1', 
            prototypes: ['1A1','1A2','1D1','1D2','1D3','1D4'],
            images: [
              {
                url: PROJECT_DIR + 'galleries/model-A/img-depa-atiko-a-1.jpg',
                original: PROJECT_DIR + 'galleries/model-A/img-depa-atiko-a-1.jpg',
                width: 320,
                height: 174,
              },
            ],
          },
          {
            code: 'G2', 
            prototypes: ['1B1','1B2','1B3','1E1','1E2'],
            images: [
              {
                url: PROJECT_DIR + 'galleries/model-B/img-depa-atiko-b-1.jpg',
                original: PROJECT_DIR + 'galleries/model-B/img-depa-atiko-b-1.jpg',
                width: 320,
                height: 174,
              },
              {
                url: PROJECT_DIR + 'galleries/model-B/img-depa-atiko-b-2.jpg',
                original: PROJECT_DIR + 'galleries/model-B/img-depa-atiko-b-2.jpg',
                width: 320,
                height: 174,
              },
            ],
          },
          {
            code: 'G3', 
            prototypes: ['1C1','1C2','1C3'],
            images: [
              {
                url: PROJECT_DIR + 'galleries/model-C/img-depa-atiko-c-1.jpg',
                original: PROJECT_DIR + 'galleries/model-C/img-depa-atiko-c-1.jpg',
                width: 320,
                height: 174,
              },
            ],
          },
        ],
        gallery: [
            {
              url: PROJECT_DIR + 'gallery/img-depa-atiko-1.jpg',
              original: PROJECT_DIR + 'gallery/img-depa-atiko-1.jpg',
              width: 320,
              height: 174,
            },
            {
              url: PROJECT_DIR + 'gallery/img-depa-atiko-2.jpg',
              original: PROJECT_DIR + 'gallery/img-depa-atiko-2.jpg',
              width: 320,
              height: 174,
            },
            {
              url: PROJECT_DIR + 'gallery/img-depa-atiko-3.jpg',
              original: PROJECT_DIR + 'gallery/img-depa-atiko-3.jpg',
              width: 320,
              height: 174,
            },
            {
              url: PROJECT_DIR + 'gallery/img-depa-atiko-4.jpg',
              original: PROJECT_DIR + 'gallery/img-depa-atiko-4.jpg',
              width: 320,
              height: 174,
            },
        ],
        views: [
          { name: 'cerrodelasilla', prototypes: ['2C'], asset: PROJECT_DIR + 'views/cerrodelasilla.jpg' },
          { name: 'sierramadre', prototypes: ['2B2','2D'], asset: PROJECT_DIR + 'views/sierramadre.jpg' },
          { name: 'santalucia', prototypes: ['2A','2B1'], asset: PROJECT_DIR + 'views/santalucia.jpg' },
        ]
    },
    buildings : []
} 
export default project;